import ItemLink from './linkItem'
import MainItem from './mainItem'
import Modal from './modal';


const Item = ({ itemsData, handleItemClick, selectedItem, commonData, handleCloseModal, handleService, main_list }) => {

    const handleMainItemClick = async (key_id) => {
        let tg = window.Telegram.WebApp;
        tg.expand();
        let tg_user = tg.initDataUnsafe.user?.id || 0;
        let url_alert = `https://thouse.cc/api/order/alertview?iid=${key_id}&uid=${tg_user}`;
        try {
            const response = await fetch(url_alert);
            if (!response.ok) {
                console.error("Ошибка при отправке запроса:", response.statusText);
            }
            console.log("Запрос выполнен успешно:", response);
        } catch (error) {
            console.error("Ошибка запроса:", error);
        }
    };

    return (
        <div className="items__wrapper">
            <div className='items'>
            {itemsData.map(item => (
                !item.as_category && main_list ? (
                    <ItemLink
                        key={Math.random()}
                        id={item.pk}
                        name={item.name}
                        descr={item.description}
                        img={`https://thouse.cc${item.image}`}
                    />
                ) : (

                    <MainItem
                        key={item.pk}
                        id={item.pk}
                        name={item.name}
                        descr={item.description}
                        img={`https://thouse.cc${item.image}`}
                        text={item.text}
                        onClick={async () => {
                            handleItemClick({
                                id: item.pk,
                                name: item.name,
                                img: `https://thouse.cc${item.image}`,
                                descr: item.description,
                                text: item.text,
                                link: item.link,
                                is_service: item.is_service ? item.is_service : false
                            });
                            await handleMainItemClick(item.pk);
                        }}
                    />

                )
            ))}
            {selectedItem && (
                    <Modal
                        name={selectedItem.name}
                        img={selectedItem.img}
                        descr={selectedItem.descr}
                        text={selectedItem.text}
                        close_text = {commonData.close}
                        more_text = {commonData.more}
                        onClose={handleCloseModal}
                        onMore={
                            selectedItem.is_service? handleService : (
                                async () => {
                                    let tg = window.Telegram.WebApp;
                                    tg.expand();
                                    let tg_user = tg.initDataUnsafe.user?.id || 0;
                                    let url_alert = `https://thouse.cc/api/order/alert?iid=${selectedItem.id}&uid=${tg_user}`
                                    try {
                                        const response = await fetch(url_alert);
                                        if (!response.ok) {
                                            console.error("Ошибка при отправке запроса:", response.statusText);
                                        }
                                        console.log("Запрос выполнен успешно:", response);
                                    }
                                    catch (error) {
                                            console.error("Ошибка запроса:", error);
                                    }
                                    window.location.href = selectedItem.link;
                                }
                            )
                        }
                        id={selectedItem.id}
                    />
                 )
            }
            </div>
        </div>

    )
}

export default Item
